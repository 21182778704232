<template>
  <div>
    <b-form-group id="firm_combo" :label="label" label-class="font-weight-bolder" :label-cols="lpos" label-for="firm_select">
      <model-list-select id="firm_select" name="firm_select" :list="list" v-model="selected" placeholder="Select Firm" option-value="id" option-text="label"></model-list-select>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'
import is from 'is_js'

export default {
  name: 'FirmFilter',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number, default: 0 },
    label: { type: String, default: 'Firm' },
    labelPosition: { type: String, default: 'left' },
    onlySupervisedFirms: { type: Boolean, default: false },
    inclGnhr: { type: Boolean, default: false }
  },
  data: () => ({
    options: [],
    first: true,
    selected: ''
  }),
  computed: {
    ...mapGetters({
      o: 'getFirmsDropdown',
      p: 'getFirmsDropdown2',
      sf: 'getSupervisorFirms'
    }),
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
    list: function () {
      let self = this
      return this.options.filter(function (f) {
        let go = self.onlySupervisedFirms ? is.inArray(f.id, self.sf) : true
        return go && f.id !== 9
      })
    }
  },
  watch: {
    selected: function () {
      this.sendUpdate()
    }
  },
  created() {
    this.options = this.inclGnhr ? this.o : this.p
    if (this.onlySupervisedFirms) {
      this.selected = this.list.length > 0 ? this.list[0].id : this.id
    } else {
      this.selected = this.id
    }
  },
  methods: {
    sendUpdate() {
      if (!this.first || this.onlySupervisedFirms) {
        this.$emit('firm_change', { value: this.selected })
      } else {
        this.first = false
      }
    }
  }
}
</script>
